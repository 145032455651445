import React from "react"
import "./timeLine.css"

const Timeline = () => (
  <div className="timeline-container">
    <ul className="timeline">
      <div
        data-sal="slide-left"
        data-sal-delay="200"
        data-sal-duration="500"
        data-sal-easing="ease"
      >
        <li>
          <div className="direction-r">
            <div className="flag-wrapper">
              <span className="flag">Facebook </span>
              <span className="time-wrapper">
                <span className="time">Jan 2019 - May 2019</span>
              </span>
            </div>
            <div className="desc">
              First full time role as a Software Engineer. Worked on the
              Enterprise Engineering Team to develop a modern web portal for the
              finance team to help manage supplier data
            </div>
          </div>
        </li>
      </div>

      <div
        data-sal="slide-right"
        data-sal-delay="200"
        data-sal-duration="500"
        data-sal-easing="ease"
      >
        <li>
          <div className="direction-l">
            <div className="flag-wrapper">
              <span className="flag">Hack Reactor</span>
              <span className="time-wrapper">
                <span className="time">Aug 2018 - Jun 2019</span>
              </span>
            </div>
            <div className="desc">
              Taught JavaScript and web development fundamentals to students
              that were interested in joining the software immersive @ Hack
              Reactor
            </div>
          </div>
        </li>
      </div>

      <div
        data-sal="slide-left"
        data-sal-delay="200"
        data-sal-duration="500"
        data-sal-easing="ease"
      >
        <li>
          <div className="direction-r">
            <div className="flag-wrapper">
              <span className="flag">Freelance Software Engineer</span>
              <span className="time-wrapper">
                <span className="time">Jan 2019 - Present</span>
              </span>
            </div>
            <div className="desc">
              Developed full stack web applications, blogs, portfolios for
              multiple clients
            </div>
          </div>
        </li>
      </div>

      <div
        data-sal="slide-right"
        data-sal-delay="200"
        data-sal-duration="500"
        data-sal-easing="ease"
      >
        <li>
          <div className="direction-l">
            <div className="flag-wrapper">
              <span className="flag">Wells Fargo</span>
              <span className="time-wrapper">
                <span className="time">2014 - 2016</span>
              </span>
            </div>
            <div className="desc">
              Retail Banking -> Introduced to progamming and decided to switch
              career paths
            </div>
          </div>
        </li>
      </div>

      <div
        data-sal="slide-left"
        data-sal-delay="200"
        data-sal-duration="500"
        data-sal-easing="ease"
      >
        <li>
          <div className="direction-r">
            <div className="flag-wrapper">
              <span className="flag">UC San Diego</span>
              <span className="time-wrapper">
                <span className="time">2010 - 2013</span>
              </span>
            </div>
            <div className="desc">
              B.S in Management Science, Minor in Business
            </div>
          </div>
        </li>
      </div>
    </ul>
  </div>
)

export default Timeline
