import React from "react"
import styled from "styled-components"

const CardContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.6%;
  background: #fff;
  line-height: 1.4;
  font-family: sans-serif;
  border-radius: 0.8em;
  overflow: hidden;
  z-index: 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0 30px 18px -24px;
  margin-bottom: 2em;
  padding: 1em;
`
const CardDetails = styled.div`
  padding: 1rem;
  font-size: 1.1em;
`

const Card = inputProps => {
  return (
    <CardContainer>
      {inputProps.data.map(detail => (
        <CardDetails key={detail}>{detail}</CardDetails>
      ))}
    </CardContainer>
  )
}

export default Card
