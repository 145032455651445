import React from "react"
import Card from "../components/card"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Timeline from "../components/Timeline"
import { SectionHeader } from "../utils/commonComponents"
import { about } from "../data/portfolio"

const AboutPage = () => (
  <Layout>
    <SEO title="about" />
    <SectionHeader header="ABOUT" />
    <div
      data-sal="slide-up"
      data-sal-delay="200"
      data-sal-duration="500"
      data-sal-easing="ease"
    >
      <Card data={about.short} />
    </div>
    <h1>Timeline</h1>
    <Timeline />
  </Layout>
)

export default AboutPage
